@import "@/styles/_variables.scss";
















































































































.left-panel {
  position: fixed;
  width: 250px;
}
.right-panel {
  min-height: 100vh;
  margin-left: 250px;
  padding-left: 0px;
}
.content-wrapper {
  padding: 0px 20px 60px 20px;
  // min-height: 100vh;
} 
.uk-notification-close {
  display: block;
  color: var(--white-color);
  right: 5px;
  top: 8px;
  margin-right: 5px;
}
.message {
  right: 10px;
}
.uk-notification-message .uk-grid-divider {
  margin-left: -80px;
}

