@import "@/styles/_variables.scss";




















































































































































































































































































































.uk-breadcrumb {
  text-align: left;
}
.cumb-link:focus {
  color: var(--app-primary-color);
}
.cumb-link:hover {
  color:var(--muted-text-color)
}
